<template>
    <div class="my-second gonggao bgcolor">
        <Head :title="$t('home.anno_detail')" :show="true" ></Head>
        <div class="common-box gonggao-box">
            <div class="gonggao-list">
                <div class="detail-box">
                    <div class="detail-box-top">
                        <h2>{{item.title}}</h2>
                        <p>{{item.time}}</p>
                    </div>
                    <div class="detail-box-content">
                        <p class="detail-box-content-vip">{{$t('home.vip')}}:</p>
                        <div class="detail-box-content-text">
                            {{item.desc}}
                        </div>
                    </div>
                    <div class="detail-box-end">
                        <p class="detail-box-end-txt">{{$t('home.onebid_team')}}</p>
                        <p class="detail-box-end-txt">{{item.time | dateFormat("MM/DD/YYYY")}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import {mapState,mapMutations} from "vuex"
export default {
    data(){
        return {
            item:{}
        }
    },
    computed:{
        ...mapState(['gongGaoList'])
    },
    methods:{
        ...mapMutations(['setgongGaoList'])
    },
    mounted(){
        if(!this.gongGaoList.length>0){
            // 获取公告列表
            this.$ajax.announcement()
            .then(res=>{
                this.setgongGaoList(res.data);
                this.item = res.data.find(item=>item.id==this.$route.query.id);
            })
        }else{
            this.item = this.gongGaoList.find(item=>item.id==this.$route.query.id);
        }

        
        

    }
}
</script>
